'use strict'
import { combineSlices, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeObjectPrefs: {},
  bgColor: '#ffffff',
  status: 'idle',
  canvas: null,
  lastSelectedImage: null,
}

export const canvasSlice = createSlice({
  name: 'canvas',
  initialState,
  reducers: {
    setActiveObjectPrefs: (state, action) => {
      state.activeObjectPrefs = action.payload
    },
    clearActiveObjectPrefs: state => {
      state.activeObjectPrefs = {}
    },
    setCanvasBGColor: (state, action) => {
      state.bgColor = action.payload
    },
    applyCanvas: (state, action) => {
      state.canvas = action.payload
    },
    setLastSelectedImage: (state, action) => {
      state.lastSelectedImage = action.payload
    },
  },
})

export const {
  setActiveObjectPrefs,
  setCanvasBGColor,
  clearActiveObjectPrefs,
  applyCanvas,
  setLastSelectedImage,
} = canvasSlice.actions
export default canvasSlice.reducer
