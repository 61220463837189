import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/home/shuhermayer/child/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shuhermayer/child/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shuhermayer/child/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/shuhermayer/child/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/shuhermayer/child/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/home/shuhermayer/child/src/app/fonts.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shuhermayer/child/src/providers/SessionWrapper.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/home/shuhermayer/child/src/providers/ThemeProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/shuhermayer/child/src/store/provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["Metrika"] */ "/home/shuhermayer/child/src/ui/components/Metrika.jsx");
