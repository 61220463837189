'use client'

import { configureStore } from '@reduxjs/toolkit'
import bookReducer from './Features/bookSlice.js'
import UISliceReducer from './Features/UISlice.js'
import canvasReducer from './Features/canvasSlice.js'
import dragWidgetReducer from './Features/dragWidgetSlice.js'
import templateReducer from './Features/templateSlice.js'
import userReducer from './Features/usersSlice.js'

export const store = configureStore({
  reducer: {
    book: bookReducer,
    UISlice: UISliceReducer,
    canvas: canvasReducer,
    dragWidget: dragWidgetReducer,
    template: templateReducer,
    user: userReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
  devTools: {
    maxAge: 10,
    actionsDenylist: ['book/saveSlide/pending'],
    shouldSerialize: value => {
      console.log('value =>>', value)
      // Условие для сериализации, например, исключение больших данных
      // return value && value.type !== 'Blob' && value.type !== 'File'
    },
  },
})
