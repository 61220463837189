'use strict'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentUI: {
    widgetPanel: false,
    freeDrawMode: false,
    editors: {
      textEditor: false,
      shapeEditor: false,
      photoEditor: false,
      canvasSettings: false,
      id: null,
    },
    clippingMask: {
      clippingImageId: null,
      waitingChooseObject: false,
      waitingSetMask: false,
      maskObjectId: '',
    },
    isSlidePanelOpen: true,
    isTariffModalOpened: false,
    lastSelectedWidgetCategory: null,
    allowMoveOutside: false,
  },
  status: 'idle',
}

export const UISlice = createSlice({
  name: 'currentUI',
  initialState,
  reducers: {
    setLastSelectedWidgetCategory: (state, action) => {
      state.currentUI.lastSelectedWidgetCategory = action.payload
    },
    showWidgetPanel: (state, action) => {
      state.currentUI.widgetPanel = action.payload
    },
    setIsSlidePanelOpen: (state, action) => {
      state.currentUI.isSlidePanelOpen = action.payload
    },
    setIsTariffModalOpened: (state, action) => {
      state.currentUI.isTariffModalOpened = action.payload
    },
    setAllowMoveOutside: (state, action) => {
      state.currentUI.allowMoveOutside = action.payload
    },
    showEditor: (state, action) => {
      const resetEditors = {
        textEditor: false,
        shapeEditor: false,
        photoEditor: false,
        canvasSettings: false,
      }
      if (action.payload === 'null') {
        state.currentUI.editors = resetEditors
        if (state.currentUI.widgetPanel) {
          state.currentUI.widgetPanel = false
        }
        return
      }
      state.currentUI.editors = {
        ...resetEditors,
        [action.payload]: true,
      }
    },
    setMask: (state, action) => {
      if (action.payload === 'null') {
        state.currentUI.clippingMask.clippingImageId = null
        return
      }
      state.currentUI.clippingMask.clippingImageId = action.payload
      state.currentUI.clippingMask.waitingChooseObject = true
    },
    setMaskObject: (state, action) => {
      state.currentUI.clippingMask.maskObjectId = action.payload
    },
    stashMask: state => {
      state.currentUI.clippingMask.waitingChooseObject = false
      state.currentUI.clippingMask.waitingSetMask = true
    },
    enterFreeDrawMode: state => {
      state.currentUI.freeDrawMode = true
    },
    exitFreeDrawMode: state => {
      state.currentUI.freeDrawMode = false
    },
  },
})

export const {
  showWidgetPanel,
  showEditor,
  setMask,
  setMaskObject,
  stashMask,
  enterFreeDrawMode,
  exitFreeDrawMode,
  setIsSlidePanelOpen,
  setIsTariffModalOpened,
  setLastSelectedWidgetCategory,
  setAllowMoveOutside,
} = UISlice.actions
export default UISlice.reducer
